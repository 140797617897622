import { render, staticRenderFns } from "./forum_2024.vue?vue&type=template&id=76620656&scoped=true&"
import script from "./forum_2024.vue?vue&type=script&lang=js&"
export * from "./forum_2024.vue?vue&type=script&lang=js&"
import style0 from "./forum_2024.vue?vue&type=style&index=0&id=76620656&prod&scoped=true&lang=css&"
import style1 from "./forum_2024.vue?vue&type=style&index=1&id=76620656&prod&scoped=true&lang=css&"
import style2 from "./forum_2024.vue?vue&type=style&index=2&id=76620656&prod&scoped=true&lang=css&"
import style3 from "./forum_2024.vue?vue&type=style&index=3&id=76620656&prod&scoped=true&lang=css&"
import style4 from "./forum_2024.vue?vue&type=style&index=4&id=76620656&prod&scoped=true&lang=css&"
import style5 from "./forum_2024.vue?vue&type=style&index=5&id=76620656&prod&scoped=true&lang=css&"
import style6 from "./forum_2024.vue?vue&type=style&index=6&id=76620656&prod&scoped=true&lang=css&"
import style7 from "./forum_2024.vue?vue&type=style&index=7&id=76620656&prod&scoped=true&lang=css&"
import style8 from "./forum_2024.vue?vue&type=style&index=8&id=76620656&prod&scoped=true&lang=css&"
import style9 from "./forum_2024.vue?vue&type=style&index=9&id=76620656&prod&scoped=true&lang=css&"
import style10 from "./forum_2024.vue?vue&type=style&index=10&id=76620656&prod&scoped=true&lang=css&"
import style11 from "./forum_2024.vue?vue&type=style&index=11&id=76620656&prod&scoped=true&lang=css&"
import style12 from "./forum_2024.vue?vue&type=style&index=12&id=76620656&prod&scoped=true&lang=css&"
import style13 from "./forum_2024.vue?vue&type=style&index=13&id=76620656&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76620656",
  null
  
)

export default component.exports